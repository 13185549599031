import { Controller } from '@hotwired/stimulus'
import type { HTMLInputEvent } from '../types/html_event'

// Connects to data-controller="ex-group-allocation"
export default class extends Controller {
  // TODO rename this controller to something more general as it is used in admin reseller companies form
  static targets = ['allocationButton', 'checkbox']
  static classes = ['hide']

  declare readonly hideClass: string
  declare readonly checkboxTarget: HTMLInputElement
  declare readonly allocationButtonTargets: HTMLInputElement[]

  toggleAllocationInclusion (e: HTMLInputEvent): void {
    e.preventDefault()
    this.allocationButtonTargets.forEach(target => target.classList.toggle(this.hideClass))
    if (this.checkboxTarget.checked) {
      this.checkboxTarget.removeAttribute('checked')
    } else {
      this.checkboxTarget.setAttribute('checked', 'checked')
    }
  }
}
